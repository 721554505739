export const Validation = {
    haveRussianLetters: function (pairs) {
        const pairsWithErrors = [];
        for (let i = 0; i < pairs.length; i++) {
            if (containsRussianChars(pairs[i].text)) {
                pairsWithErrors.push(pairs[i]);
            }
        }

        return pairsWithErrors;
    },

    haveWrongSymbols: function(pairs) {
        const replacements = [    ['&#39;', "'"],
            ['&quot;', '"'],
            // Add more text and replacement pairs as needed
        ];
        
        let replacementsCount = 0;

        for (let i = 0; i < pairs.length; i++) {
            let text = pairs[i].text;
            for (let j = 0; j < replacements.length; j++) {
                const [wrongSymbol, correctSymbol] = replacements[j];
                if (text.includes(wrongSymbol)) {
                    text = text.replace(new RegExp(wrongSymbol, 'g'), correctSymbol);
                    pairs[i].text = text;
                    replacementsCount++;
                }
            }
        }
        
        if (replacementsCount > 0) {
            return `Replaced ${replacementsCount} wrong symbols.`;
        }else
        {
            return null;
        }
    },

    haveWrongSize: function (pairs) {
        const sizes = new Map();
        for (let i = 0; i < pairs.length; i++) {
            let size = `${pairs[i].image.width}x${pairs[i].image.height}`;
            if (!sizes.has(size)) {
                sizes.set(size, []);
            }
            sizes.get(size).push(pairs[i]);
        }
        
        return sizes;
    },
}

function containsRussianChars(text) {
    const russianRegex = /[\u0400-\u04FF]/;
    return russianRegex.test(text);
}