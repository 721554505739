<template>
  <div class="image-viewer">
    <div v-for="(pair, index) in pairs" :key="index" class="image-viewer__item">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-25 md-xsmall-size-100 md-small-size-50 md-medium-size-33">
          <img :src="pair.imageSrc" class="image-viewer__image" :alt="pair.imageSrc.name"/>
        </div>
        <div class="md-layout-item md-size-75 md-xsmall-size-100 md-small-size-50 md-medium-size-66">
          <div class="image-viewer__field-container">
            <span class="md-title">{{ pair.id }}</span>
            <md-field class="image-viewer__text-field">
              <label>Description</label>
              <md-textarea v-model="pair.text" @input="handleInput(index)" @blur="handleBlur(index)"
                           @keydown="handleKeyDown($event, index)" class="no-resize" :ref="`line-${index}`"/>
              <md-badge
                  v-if="pair.text !== pair.originalText"
                  md-content="changed"
                  md-position="top"
                  class="md-square image-viewer__badge"
              ></md-badge>
            </md-field>
            <div class="image-viewer__action-row">
              <md-button v-if="textChanged(index)" class="md-dense image-viewer__show-original"
                         @click="openOriginalDialog(pair)">Show Original
              </md-button>
              <md-button class="md-dense md-primary md-raised image-viewer__translate-button"
                         @click="translate.translateTextAuto(pair, saveToIndexedDB(index))"
                         :disabled="!translate.canBeTranslated(pair)">
                Translate
                <md-tooltip md-direction="top">{{ getTooltipText(pair.text) }}</md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog :md-active.sync="showOriginalDialog" md-backdrop>
      <md-card class="image-viewer__dialog-card">
        <div class="md-layout md-gutter image-viewer__dialog-content">
          <div class="md-layout-item md-size-50 md-size-100-sm image-viewer__dialog-left">
            <md-card-media>
              <img :src="currentPair.imageSrc" :alt="'Image for pair ' + currentPair.id"
                   class="image-viewer__dialog-image"/>
            </md-card-media>
          </div>
          <div class="md-layout-item md-size-50 md-size-100-sm image-viewer__dialog-right">
            <md-card-header>
              <md-card-header-text>
                <span class="md-title">{{ currentPair.id }}</span>
              </md-card-header-text>
            </md-card-header>
            <md-card-content>
              <p>{{ currentPair.originalText }}</p>
            </md-card-content>
            <md-card-actions>
              <md-button class="md-primary" @click="handleDialogCopyClick(currentPair.originalText)">Copy</md-button>
              <md-button class="md-primary" @click="showOriginalDialog = false">Close</md-button>
            </md-card-actions>
          </div>
        </div>
      </md-card>
    </md-dialog>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import {openIndexedDB} from '@/helpers';
import translate from "@/utils/translate";

export default {
  props: {
    pairs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Other data properties
      showOriginalDialog: false,
      currentPair: {
        id: null,
        imageSrc: '',
        originalText: '',
      },
      translate: translate,
    };
  },
  methods: {
    handleInput(index) {
      this.debouncedSave(index);
    },

    handleBlur(index) {
      this.saveToIndexedDB(index);
    },

    handleKeyDown(event, index) {
      if (event.ctrlKey && event.key === 'Enter') {
        if (translate.containsRussianChars(this.pairs[index].text)) {
          translate.translateText(this.pairs[index], 'ru', 'en', this.saveToIndexedDB(index));
        }

        // Focus on the next line if it's not the last line
        if (index < this.pairs.length - 1) {
          this.focusOnLine(index + 1);
        }

        // Prevent the default behavior of the Enter key
        event.preventDefault();
      } else if (event.shiftKey && event.key === 'Enter') {
        if (translate.canBeTranslated(this.pairs[index].text)) {
          translate.translateTextAuto(this.pairs[index], this.saveToIndexedDB(index));
        }

        // Prevent the default behavior of the Enter key
        event.preventDefault();
      }
    },

    focusOnLine(index) {
      const lineRef = `line-${index}`;
      if (this.$refs[lineRef] && this.$refs[lineRef][0] && this.$refs[lineRef][0].$el) {
        const textarea = this.$refs[lineRef][0].$el;
        if (textarea) {
          textarea.focus();
          textarea.scrollIntoView({behavior: 'smooth', block: 'center'});
        } else {
          console.warn(`Unable to find textarea in ref '${lineRef}'`);
        }
      } else {
        console.warn(`Ref '${lineRef}' not found or $el property is missing`);
      }
    },
    
    focusOnLineWithId(id){
      const index = this.pairs.findIndex(pair => pair.id === id);
      if (index !== -1) {
        this.focusOnLine(index);
      }
    },

    openOriginalDialog(pair) {
      this.currentPair = pair;
      this.showOriginalDialog = true;
    },

    handleDialogCopyClick(text) {
      this.copyToClipboard(text);
      this.showOriginalDialog = false;
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },

    async saveToIndexedDB(index) {
      const pair = this.pairs[index];

      // Generate an id for the object store based on the file names
      const id = pair.id;

      try {
        const db = await openIndexedDB();
        const transaction = db.transaction('ImageTextPairs', 'readwrite');
        const store = transaction.objectStore('ImageTextPairs');

        // Save the updated text content and image source
        store.put({id, text: pair.text, imageSrc: pair.imageSrc});

        if (process.env.NODE_ENV === 'development') {
          transaction.oncomplete = () => {
            console.log(`Saved pair with id: ${id}`);
          };
        }

        transaction.onerror = () => {
          console.error(`Error saving pair with id: ${id}`);
        };
      } catch (error) {
        console.error('Error opening database:', error);
      }
    },

    debouncedSave: debounce(function (index) {
      this.saveToIndexedDB(index);
    }, 5000),

    getTooltipText(text) {
      if (translate.containsRussianChars(text)) {
        return 'Translate to EN using Google Translate';
      } else {
        return 'Translate to RU using Google Translate';
      }
    },

    // containsRussianChars(text) {
    //   const russianRegex = /[\u0400-\u04FF]/;
    //   return russianRegex.test(text);
    // },
    //
    // containsEnglishChars(text) {
    //   const englishRegex = /[A-Za-z]/;
    //   return englishRegex.test(text);
    // },

    textChanged(index) {
      const pair = this.pairs[index];
      return pair.text !== pair.originalText;
    },

    // canBeTranslated(text) {
    //   return this.containsRussianChars(text) || this.containsEnglishChars(text);
    // },
    //
    // async translateTextAuto(index) {
    //   const pair = this.pairs[index];
    //   if (this.containsRussianChars(pair.text)) {
    //     await this.translateText(index, 'ru', 'en');
    //   } else if (this.containsEnglishChars(pair.text)) {
    //     await this.translateText(index, 'en', 'ru');
    //   }
    // },
    //
    // async translateText(index, sourceLang, targetLang) {
    //   const pair = this.pairs[index];
    //   const hash = generateHash(pair.text);
    //
    //   // Check if the translation is cached in local storage
    //   const cachedTranslation = localStorage.getItem(`translation-${hash}`);
    //   if (cachedTranslation) {
    //     pair.text = cachedTranslation;
    //     if (process.env.NODE_ENV === 'development') {
    //       console.log('translation received from cache');
    //     }
    //   } else {
    //     // Call the translation API if the translation is not cached
    //     const translatedText = await this.doTranslateAPICall(pair.text, sourceLang, targetLang);
    //
    //     // Save the translation to local storage
    //     localStorage.setItem(`translation-${hash}`, translatedText);
    //
    //     pair.text = translatedText;
    //   }
    //
    //   await this.saveToIndexedDB(index)
    // },
    //
    // async doTranslateAPICall(text, sourceLang, targetLang) {
    //   try {
    //     const k = 'AIzaSyDEwx2YpJj1INjTfWLovBATH9QiuaBqqOA';
    //     const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
    //     const params = {
    //       q: text,
    //       target: targetLang,
    //       key: k,
    //       source: sourceLang,
    //     };
    //     const response = await axios.post(apiUrl, {}, {params});
    //
    //     if (response.data && response.data.data && response.data.data.translations && response.data.data.translations.length > 0) {
    //       return response.data.data.translations[0].translatedText;
    //     } else {
    //       throw new Error('Translation failed');
    //     }
    //   } catch (error) {
    //     console.error('Translation error:', error);
    //     // You can also display an error message to the user if necessary
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
@import './ImageViewer.scss';
</style>