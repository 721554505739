import axios from 'axios';
import {generateHash} from '@/helpers';

export default {
    containsRussianChars(text) {
        const russianRegex = /[\u0400-\u04FF]/;
        return russianRegex.test(text);
    },

    containsEnglishChars(text) {
        const englishRegex = /[A-Za-z]/;
        return englishRegex.test(text);
    },

    canBeTranslated(text) {
        return this.containsRussianChars(text) || this.containsEnglishChars(text);
    },

    async translateTextAuto(pair, callback) {
        if (this.containsRussianChars(pair.text)) {
            await this.translateText(pair, 'ru', 'en', callback);
        } else if (this.containsEnglishChars(pair.text)) {
            await this.translateText(pair, 'en', 'ru', callback);
        }
    },

    async translateText(pair, sourceLang, targetLang, callback) {
        const hash = generateHash(pair.text);

        // Check if the translation is cached in local storage
        const cachedTranslation = localStorage.getItem(`translation-${hash}`);
        if (cachedTranslation) {
            pair.text = cachedTranslation;
            if (process.env.NODE_ENV === 'development') {
                console.log('translation received from cache');
            }
        } else {
            // Call the translation API if the translation is not cached
            const translatedText = await this.doTranslateAPICall(pair.text, sourceLang, targetLang);

            // Save the translation to local storage
            localStorage.setItem(`translation-${hash}`, translatedText);
            localStorage.setItem(`translation-${generateHash(translatedText)}`, pair.text);

            pair.text = translatedText;
        }

        if (callback && typeof callback === 'function') {
            callback();
        }
    },

    async doTranslateAPICall(text, sourceLang, targetLang) {
        try {
            const k = 'AIzaSyDEwx2YpJj1INjTfWLovBATH9QiuaBqqOA';
            const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
            const params = {
                q: text,
                target: targetLang,
                key: k,
                source: sourceLang,
            };
            const response = await axios.post(apiUrl, {}, {params});

            if (response.data && response.data.data && response.data.data.translations && response.data.data.translations.length > 0) {
                return response.data.data.translations[0].translatedText;
            } else {
                throw new Error('Translation failed');
            }
        } catch (error) {
            console.error('Translation error:', error);
            // You can also display an error message to the user if necessary
        }
    },
}