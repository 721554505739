export async function openIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('ImageTextDatabase', 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore('ImageTextPairs', { keyPath: 'id' });
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

export function generateHash(text) {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        hash = (hash << 5) - hash + charCode;
        hash |= 0; // Convert to a 32-bit integer
    }
    return hash;
}