<template>
  <div id="app" class="page-container full-height">
    <md-app md-waterfall md-mode="fixed" class="full-height">
      <md-app-toolbar class="md-primary">
        <div class="md-toolbar-section-start">
          <!--          <img src="path/to/your/logo.png" alt="Logo" class="logo" />-->
          <span class="md-title header__title" @click="titleClicked">Dataset Editor Utility</span>
        </div>
        <div class="md-toolbar-section-end">
          <md-menu v-if="pairs.length > 0" md-size="medium" md-align-trigger>
            <md-button md-menu-trigger>Validation</md-button>

            <md-menu-content>
              <md-menu-item @click="ValidateRussianLetters">Russian letters</md-menu-item>
              <md-menu-item @click="ValidateWrongSymbols">Wrong symbols</md-menu-item>
              <md-menu-item @click="ValidateSizes">Sizes</md-menu-item>
            </md-menu-content>
            <md-snackbar md-position='center' :md-duration=5000
                         :md-active.sync="showValidationSnackbar" md-persistent>
              <span>{{ validationMessage }}</span>
            </md-snackbar>
          </md-menu>
          <file-selector @files-selected="processFiles" style="display: none">
            <md-button ref="fileInput" class="md-icon-button">
              <md-icon>folder_open</md-icon>
              <md-tooltip md-direction="bottom">Add new files</md-tooltip>
            </md-button>
          </file-selector>
          <md-button v-show="pairs.length > 0" class="md-icon-button" @click="saveAll">
            <md-icon>file_download</md-icon>
            <md-tooltip md-direction="bottom">Save</md-tooltip>
          </md-button>
        </div>
      </md-app-toolbar>
      <md-app-content>
        <div id="empty-state" v-show="pairs.length === 0 && !isLoading" style="height:100%">
          <md-empty-state
              md-icon="model_training"
              md-label="Add files to create your dataset"
              md-description="Upload image and text files with matching names, edit descriptions, and save your dataset as a zip file for further use.">
            <md-button class="md-primary md-raised" @click="startFilesLoading">Select first files</md-button>
          </md-empty-state>
        </div>
        <div class="spinner-center">
          <md-progress-spinner
              v-show="isLoading"
              md-mode="indeterminate"
              :md-diameter="100"
          ></md-progress-spinner>
        </div>
        <image-viewer :pairs="pairs" ref="imageViewer"/>
        <div class="save-all-button-container">
          <md-button v-show="pairs.length > 0" class="md-raised md-primary" @click="saveAll">Save All</md-button>
        </div>
        <md-speed-dial md-event="click" class="md-bottom-right" style="z-index: 20">
          <md-speed-dial-target v-show="pairs.length > 0">
            <md-icon>menu</md-icon>
          </md-speed-dial-target>

          <md-speed-dial-content>
            <md-button class="md-icon-button" @click="clearList">
              <md-icon>delete</md-icon>
              <md-tooltip md-direction="left">Clear list</md-tooltip>
            </md-button>
            <md-button class="md-icon-button" @click="saveAll">
              <md-icon>file_download</md-icon>
              <md-tooltip md-direction="left">Save</md-tooltip>
            </md-button>
            <md-button class="md-icon-button" @click="startFilesLoading">
              <md-icon>add</md-icon>
              <md-tooltip md-direction="left">Add new files</md-tooltip>
            </md-button>
          </md-speed-dial-content>
        </md-speed-dial>
      </md-app-content>
    </md-app>
    <md-dialog
        :md-active.sync="showClearConfirmationDialog"
        md-fullscreen
        md-backdrop
    >
      <md-dialog-title>Clear List Confirmation</md-dialog-title>
      <md-dialog-content>
        Are you sure you want to clear the list of files?
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="showClearConfirmationDialog = false">Cancel</md-button>
        <md-button @click="confirmClearList">Clear List</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import JSZip from 'jszip';
import FileSelector from './components/FileSelector.vue';
import ImageViewer from './components/ImageViewer.vue';
import {openIndexedDB} from '@/helpers';
import {Validation} from "@/utils/validation";

const demoImages = [
  {
    url: 'https://picsum.photos/id/10/800/600',
    description: 'A group of cats sitting on top of a building in the sky with clouds behind them and a sky background.'
  },
  {
    url: 'https://picsum.photos/id/20/800/600',
    description: 'A cartoon bear is standing in a room with a lot of toys and a basket of fruit on the floor.'
  },
  {
    url: 'https://picsum.photos/id/30/800/600',
    description: 'A group of cartoon animals standing around a table with drinks and plates on it, and a man holding a plate.'
  },
  {
    url: 'https://picsum.photos/id/40/800/600',
    description: 'A cartoon of a dinosaur and a fish in a room with plants and a lamp on the ceiling and a window.'
  },
  {
    url: 'https://picsum.photos/id/50/800/600',
    description: 'A cartoon of a crocodile eating cake and drinking wine with a candle in the background of a party scene.'
  },
  {
    url: 'https://picsum.photos/id/60/800/600',
    description: 'A beautiful landscape with a winding river, lush green trees, and majestic mountains in the distance.'
  },
  {
    url: 'https://picsum.photos/id/70/800/600',
    description: 'A cityscape at night with illuminated buildings and a stunning sky filled with stars and a bright moon.'
  },
  {
    url: 'https://picsum.photos/id/80/800/600',
    description: 'A close-up of a colorful butterfly resting on a vibrant flower in a lush garden.'
  },
  {
    url: 'https://picsum.photos/id/90/800/600',
    description: 'An aerial view of a coastal city with a beautiful harbor and boats sailing in the sea.'
  },
  {
    url: 'https://picsum.photos/id/100/800/600',
    description: 'A serene sunset over a calm lake surrounded by trees and a reflection of the sky on the water.'
  }
];


export default {
  name: 'App',
  components: {
    FileSelector,
    ImageViewer,
  },

  data() {
    return {
      pairs: [],
      showClearConfirmationDialog: false,
      isLoading: false,
      titleClicks: 0,
      showValidationSnackbar: false,
      validationMessage: '',
    };
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {
    handleKeyDown(event) {
      if (event.ctrlKey && (event.code === 'KeyS' || event.keyCode === 83)) {
        event.preventDefault(); // Prevent browser's default save action
        this.saveAll();
      }
    },

    titleClicked() {
      this.titleClicks++;
      if (this.titleClicks === 10) {
        this.activateDemoMode();
      }
    },

    activateDemoMode() {
      const newPairs = demoImages.map((image, index) => {
        return {
          id: `demo-image-${index + 1}`,
          imageSrc: image.url,
          originalText: image.description,
          text: image.description,
          imageFile: null,
          textFile: null,
        };
      });
      this.pairs = newPairs;
    },

    startFilesLoading() {
      this.$refs.fileInput.$el.click();
    },

    async processFiles(files) {
      this.isLoading = true;

      if (typeof window.ym !== 'undefined') {
        window.ym(92873157, 'reachGoal', 'loadFiles');
      }

      const fileMap = new Map();
      const fileReaderPromises = [];

      for (const file of files) {
        const nameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        // Extract the index from the filename
        const indexMatch = file.name.match(/^(\d+)-/);
        const index = indexMatch ? parseInt(indexMatch[1], 10) : null;

        if (!fileMap.has(nameWithoutExtension)) {
          fileMap.set(nameWithoutExtension, {image: null, text: null, index: index});
        }

        const entry = fileMap.get(nameWithoutExtension);

        if (file.type.startsWith('image/')) {
          fileReaderPromises.push(
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                  entry.image = {file, src: reader.result};
                  resolve();
                };
                reader.readAsDataURL(file);
              })
          );
        } else if (file.type === 'text/plain') {
          fileReaderPromises.push(
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                  entry.text = {file, content: reader.result};
                  resolve();
                };
                reader.readAsText(file);
              })
          );
        }
      }

      await Promise.all(fileReaderPromises);

      const newPairs = Array.from(fileMap.values()).filter((pair) => pair.image && pair.text)
          .filter((pair) => !this.pairs.some((existingPair) => existingPair.id === pair.image.file.name));

      this.pairs = this.pairs.concat(newPairs.map((pair) => {
        const nameWithoutExtension = pair.image.file.name.split('.').slice(0, -1).join('.');
        const img = new Image();
        img.src = pair.image.src;
        return {
          id: nameWithoutExtension,
          image: img,
          imageSrc: pair.image.src,
          originalText: pair.text.content,
          text: pair.text.content,
          imageFile: pair.image.file,
          textFile: pair.text.file,
        };
      }));

      await this.loadFromIndexedDB();

      this.isLoading = false;
    },

    async loadFromIndexedDB() {
      try {
        const db = await openIndexedDB();
        const transaction = db.transaction('ImageTextPairs', 'readonly');
        const store = transaction.objectStore('ImageTextPairs');

        for (const pair of this.pairs) {
          const request = store.get(pair.id);

          request.onsuccess = () => {
            const savedPair = request.result;
            if (savedPair) {
              pair.text = savedPair.text;
              pair.imageSrc = savedPair.imageSrc;
            }
          };

          request.onerror = () => {
            console.error(`Error loading pair with id: ${pair.id}`);
          };
        }
      } catch (error) {
        console.error('Error opening database:', error);
      }
    },

    async saveAll() {
      if (typeof window.ym !== 'undefined') {
        window.ym(92873157, 'reachGoal', 'saveAll')
      }

      const zip = new JSZip();

      for (const pair of this.pairs) {
        const {id, imageSrc, text, imageFile, textFile} = pair;

        // Add the image file to the zip or download it if imageFile is null
        if (imageFile) {
          zip.file(imageFile.name, imageFile);
        } else {
          const response = await fetch(imageSrc);
          const imageData = await response.blob();
          zip.file(`image_${id}.jpg`, imageData);
        }

        // Add the updated text file to the zip or create a new one if textFile is null
        const textBlob = new Blob([text], {type: 'text/plain;charset=utf-8'});
        if (textFile) {
          zip.file(textFile.name, textBlob);
        } else {
          zip.file(`text_${id}.txt`, textBlob);
        }
      }

      // Generate and download the zip file
      const content = await zip.generateAsync({type: 'blob'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = 'dataset.zip';
      link.click();
      URL.revokeObjectURL(link.href);
    },

    clearList() {
      this.showClearConfirmationDialog = true;
    },

    confirmClearList() {
      this.pairs = [];
      this.showClearConfirmationDialog = false;
    },

    ValidateRussianLetters() {
      const pairsWithError = Validation.haveRussianLetters(this.pairs);
      if (pairsWithError.length > 0) {
        this.showValidationSnackbar = true;
        this.validationMessage = `Error. There is a Russian letter in ${pairsWithError.length} pairs`;
        this.$refs.imageViewer.focusOnLineWithId(pairsWithError[0].id);
      } else {
        this.showValidationSnackbar = true;
        this.validationMessage = 'Ok! There is no Russian letters in the dataset';
      }
    },

    ValidateWrongSymbols() {
      const result = Validation.haveWrongSymbols(this.pairs);
      if (result != null) {
        this.showValidationSnackbar = true;
        this.validationMessage = result;
      } else {
        this.showValidationSnackbar = true;
        this.validationMessage = 'Ok! There is no wrong symbols in the dataset';
      }
    },

    ValidateSizes() {
      const existingSizes = Validation.haveWrongSize(this.pairs);
      if (existingSizes.length > 1) {
        this.showValidationSnackbar = true;
        this.validationMessage = `Error. Sizes are not similar: ${Array.from(existingSizes.keys()).join(', ')}`;
        this.$refs.imageViewer.focusOnLineWithId(existingSizes[1][0].id);
      } else {
        this.showValidationSnackbar = true;
        this.validationMessage = `Ok! There is no wrong sizes in the dataset. ${Array.from(existingSizes.keys()).join(', ')}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>