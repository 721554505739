<template>
  <div>
    <input ref="fileInput" type="file" multiple @change="handleFiles" style="display: none" />
    <div @click="$refs.fileInput.click()">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleFiles(event) {
      const files = Array.from(event.target.files);
      this.$emit('files-selected', files);
    },
  },
};
</script>
